import { Dialog } from '@mui/material';
import { Script } from 'gatsby';
import { useEffect } from 'react';

type Props = {
    open: boolean;
    close(): void;
};

export const SubscribeModal = ({ open, close }: Props) => {
    return (
        <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title" keepMounted={true}>
            <div style={{ display: `${open ? 'block' : 'none'}` }} className="ml-embedded" data-form="klIQxJ"></div>
            <Script
                strategy="post-hydrate"
                dangerouslySetInnerHTML={{
                    __html: `
(function (w, d, e, u, f, l, n) {
    w[f] = w[f] || function () {
        (w[f].q = w[f].q || [])
            .push(arguments);
    }, l = d.createElement(e), l.async = 1, l.src = u,
        n = d.getElementsByTagName(e)[0], n.parentNode.insertBefore(l, n);
})
    (window, document, 'script', 'https://assets.mailerlite.com/js/universal.js', 'ml');
ml('account', '1059701');
          `,
                }}
                onLoad={() => {
                    console.log('Inline script has been injected and executed!');
                    // Your custom logic that depends on the script execution
                }}
            />
        </Dialog>
    );
};
