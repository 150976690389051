import * as React from 'react';
import { newSerializedStyles } from '../utils/utils';
import { css } from '@emotion/react';

const styles = newSerializedStyles();
styles.content = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    padding-left: 20px;
    background-color: var(--light-gray);
    text-align: center;
    bottom: 0;
    height: 50px;
    font-size: 1rem;
    margin-top: 40px;
    font-size: 0.9rem;

    @media (max-width: 700px) {
        font-size: 0.8rem;
    }

    .lobster {
        width: 22px;
        height: 22px;
        position: relative;
        top: 4px;
    }

    a {
        text-decoration: none;
        transition: opacity 0.2s linear;
        color: var(--active);
    }

    a:hover {
        opacity: 0.6;
    }
`;

export function Footer() {
    return (
        <div css={styles.content}>
            <div>
                Built with <img className="lobster" src="/lobster.svg" /> in Halifax, Nova Scotia by{' '}
                <a href="https://www.linkedin.com/in/joshweston/" target="_blank">
                    JW
                </a>
            </div>
        </div>
    );
}
