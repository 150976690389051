import * as React from 'react';
import { css } from '@emotion/react';
import { newSerializedStyles } from '../utils/utils';

const styles = newSerializedStyles();

styles.content = css`
    @media (max-width: 700px) {
        font-size: 0.8rem;
    }
`;

type Props = {
    children: React.ReactNode;
};

export function Content({ children }: Props) {
    return <div css={styles.content}>{children}</div>;
}
