import * as React from 'react';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { newSerializedStyles } from '../utils/utils';
import { css } from '@emotion/react';

const styles = newSerializedStyles();
styles.content = css`
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100dvh;
    /* font-size: 1.6rem;

    @media (max-width: 700px) {
        :root {
            font-size: 0.8rem;
        }
    } */
`;

type Props = {
    // pageTitle: string;
    children: React.ReactNode;
};

export function Layout({ children }: Props) {
    return (
        <div css={styles.content}>
            <Header />
            <Content>{children}</Content>
            <Footer />
        </div>
    );
}
