import React from 'react';
import { newSerializedStyles } from '../utils/utils';
import { css } from '@emotion/react';
import { Button, Drawer, IconButton } from '@mui/material';
import { MenuOutlined as MenuIcon } from '@mui/icons-material';
import { CloseOutlined as CloseIcon } from '@mui/icons-material';
import { NotificationsActive } from '@mui/icons-material';
import { Link } from 'gatsby';
import { useState } from 'react';
import { SubscribeModal } from './SubscribeModal';

const styles = newSerializedStyles();

styles.content = css`
    height: 50px;
    position: sticky;
    z-index: 100;
    padding-inline-start: var(--spacing-desktop-padding-gutter);
    padding-inline-end: var(--spacing-desktop-padding-gutter);
    padding-top: var(--spacing-desktop-padding-top);
    top: 0;

    a {
        text-decoration: none;
        color: var(--dark-purple);
        &[aria-current='page'] {
            color: inherit;
        }

        &:visited {
            color: var(--dark-purple);
        }

        &:hover {
            color: inherit;
        }
    }

    .container {
        display: grid;
        column-gap: 12px;
        grid-template-rows: 1fr;
        grid-template-columns: auto 1fr auto;
        margin: 0 auto;
        max-width: 1400px;
        .left {
            .logo {
                font-weight: bold;
                font-size: 1.4rem;
                height: 100%;
                box-sizing: border-box;
                box-shadow: var(--dropshadow-sm);

                transition: background-color 0.2s ease;
                &:hover {
                    background-color: var(--lightbg);
                }
                user-select: none;
                cursor: pointer;
            }

            .tutorials {
                // TOD: adding this with a coming soon tool-top and disabled
            }
        }

        .right {
            display: flex;
            gap: 12px;
            justify-content: end;

            .container {
                display: flex;
                gap: 10px;
                box-shadow: var(--dropshadow-sm);
                align-items: center;
                user-select: none;
                padding: 0px 10px;
                height: 100%;

                .link {
                    cursor: pointer;
                    gap: 6px;
                    transition: background-color 0.2s ease;
                    &:hover {
                        background-color: var(--lightbg);
                    }
                    border-radius: 10px;
                    padding: 10px 20px;
                }
            }
        }
    }

    #menu-button {
        display: none;
    }

    #app-link {
        background-color: white;
    }

    // 700px or less (tablet/mobile) (switch to hamburger button)
    @media (max-width: 700px) {
        --logo-width: 100px;
        --height: 80px;
        --font-size: 11px;

        #menu-button {
            display: flex;
        }

        #menu-links {
            display: none;
        }

        #app-link {
            display: none;
        }
    }
`;

styles.drawer = css`
    padding: min(10%, 45px);
    color: var(--accent-green);
    font-size: 1.2rem;

    .menu-container {
        display: flex;
        flex-direction: column;
        min-height: 25dvh;

        .links-container {
            flex: 1;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        a {
            display: block;
            text-decoration: none;
            font-weight: medium;
            color: var(--darktext);
            cursor: pointer;
            margin: 20px 0px;
            transition: background-color 0.2s ease;
            border-radius: 10px;
            padding: 10px 20px;

            &:hover {
                background-color: var(--lightbg);
            }
        }
    }

    .disabled-link {
        color: var(--text-gray-light);
        font-weight: medium;
        cursor: pointer;
        font-size: 1.6rem;
    }

    #close-menu {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 28px;
        color: var(--text-gray-dark);
        transition: color 0.1s linear;
        &:hover {
            color: var(--accent-green);
        }
    }
`;

styles.appButton = css`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: rgb(0, 104, 255);
    background: var(--blue-gradient);
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    height: 100%;
    transition: opacity 0.2s ease;
    box-shadow: var(--dropshadow-lg);
    white-space: nowrap;

    &:hover {
        opacity: 0.8;
    }
`;

export function Header() {
    const [openMenu, setOpenMenu] = useState(false);
    const [openSubscribeModal, setOpenSubscribeModal] = useState(false);

    const toggleMenu = (toggle: boolean) => {
        setOpenMenu(toggle);
    };

    return (
        <>
            <div css={styles.content}>
                <Drawer anchor="top" open={openMenu} onClose={() => toggleMenu(false)}>
                    <div css={styles.drawer}>
                        <IconButton
                            id="close-menu"
                            tabIndex={0}
                            className="material-icons"
                            onKeyDown={(event) =>
                                ['keydown', 'keypress'].includes(event.type) &&
                                ['Enter', ' '].includes(event.key) &&
                                toggleMenu(false)
                            }
                            onClick={() => toggleMenu(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className="menu-container">
                            <ul className="links-container">
                                <li>
                                    <Link onClick={() => toggleMenu(false)} to="/contact">
                                        Contact
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={() => toggleMenu(false)} to="/about">
                                        About
                                    </Link>
                                </li>
                                {/* <li>
                                <Link onClick={() => toggleMenu(false)} to="/faq">
                                    Frequently Asked Questions
                                </Link>
                            </li> */}
                            </ul>
                            <div id="app-link-menu" className="app">
                                <Button
                                    aria-label="News & Updates"
                                    css={styles.appButton}
                                    style={{ width: '100%' }}
                                    endIcon={
                                        <NotificationsActive
                                            style={{ fontSize: '16px', position: 'relative', top: '-1px' }}
                                        />
                                    }
                                    onClick={() => setOpenSubscribeModal(true)}
                                >
                                    News & Updates
                                </Button>
                            </div>
                        </div>
                    </div>
                </Drawer>
                <div className="container">
                    <div className="left">
                        <Link to="/">
                            <div className="logo outline">Noodlr.ai</div>
                        </Link>
                    </div>
                    <div className="spacer"></div>
                    <div className="right">
                        {/* <IconButton aria-label="menu" id="menu">
                        <MenuIcon />
                    </IconButton> */}
                        {/* <div className="container outline"> */}
                        <Button
                            id="menu-button"
                            variant="outlined"
                            style={{
                                borderRadius: '10px',
                                backgroundColor: 'white',
                                boxShadow: 'var(--dropshadow-sm)',
                            }}
                            onKeyDown={(event) =>
                                ['keydown', 'keypress'].includes(event.type) &&
                                ['Enter', ' '].includes(event.key) &&
                                toggleMenu(true)
                            }
                            onClick={() => toggleMenu(true)}
                        >
                            <MenuIcon />
                        </Button>
                        <div id="menu-links" className="container outline">
                            <Link to="/contact" style={{ textDecoration: 'none' }}>
                                <span className="link">Contact</span>
                            </Link>
                            <Link to="/about">
                                <span className="link">About</span>
                            </Link>
                        </div>
                        <div id="app-link" className="app">
                            <Button
                                aria-label="News & Updates"
                                css={styles.appButton}
                                endIcon={
                                    <NotificationsActive
                                        style={{ fontSize: '16px', position: 'relative', top: '-1px' }}
                                    />
                                }
                                onClick={() => setOpenSubscribeModal(true)}
                            >
                                News & Updates
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <SubscribeModal open={openSubscribeModal} close={() => setOpenSubscribeModal(false)} />
        </>
    );
}
